// @flow
// $FlowFixMe
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import { NEW_RELIC_OPTION_INIT, NEW_RELIC_OPTION_INFO, NEW_RELIC_OPTION_LOADER_CONFIG } from 'qap/constants'

const NEW_RELIC_OPTIONS = {
  init: JSON.parse(NEW_RELIC_OPTION_INIT),
  info: JSON.parse(NEW_RELIC_OPTION_INFO),
  loader_config: JSON.parse(NEW_RELIC_OPTION_LOADER_CONFIG)
}

export default {
  options: NEW_RELIC_OPTIONS,
  init: () => new BrowserAgent(NEW_RELIC_OPTIONS)
}
