// @flow
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'utilities/fileSystemCheck'

import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import 'regenerator-runtime/runtime'

import store from 'state/configureStore'
import history from 'state/history'
import { initGoogleAnalytics } from 'utilities/googleAnalytics'
import newRelicAgent from 'utilities/newRelic'

import App from './components/App'
import './styles.css'

import { SENTRY_DSN } from './qap/constants'

Sentry.init({ dsn: SENTRY_DSN })

newRelicAgent.init()

const rootElement = document && document.getElementById('app')

// Configurate Google Analytics
initGoogleAnalytics({history})

if (rootElement) {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>,
    rootElement
  )
}
